import React, { useState, useEffect } from 'react';
import { Input, Form } from 'antd';
import home from '../../images/bg-banner.png'
import Layout from '../components/Layout';
import './index.css';
import api from '../util/api';
import Swal from 'sweetalert2';
import TurnStile from '../components/TurnStile';

const { TextArea } = Input;
export default function Contact(props) {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [translate, setTranslate] = useState(null);
  const [lang, setLang] = useState(null);
  const [token, setToken] = useState(null);

  const onChangeEmail = (e) => {
    setEmail(e.target.value)
  }

  const onChangeMessage = (e) => {
    setMessage(e.target.value)
  };

  const handleSubmit = (e) => {
    console.log("handleSubmit")
  }

  const onSend = () => {

    if (window.turnstile.isExpired("#turnstile-container")) {
      window.turnstile.reset("#turnstile-container");
      return Swal.fire('', 'โปรดยืนยันตัวตน', 'error')
    }

    if (!String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )) return Swal.fire('', 'โปรดระบุอีเมล์', 'error')
    if (message === '') return Swal.fire('', 'โปรดระบุข้อความ', 'error')

    //

    api.sendEmail({
      email: email,
      text: message,
      token: token
    })
      .then(response => {
        console.log("response", response)
        Swal.fire('', 'ส่งข้อความเรียบร้อยแล้ว', 'success')
      })
      .catch(err => {
        Swal.fire('', 'ส่งไม่สำเร็จ', 'error')
      })

  }

  return (
    <Layout
      Role={['public', 'public-login', 'partner', 'eef', 'representative']} backgroundImage={`url(${home})`} className='h-[120px]'
      getLang={(translate, lang) => {
        setTranslate(translate)
        setLang(lang)
      }}
    >
      <div className='h-[120px]'></div>
      <div className="max-w-[1300px] 2xl:max-w-[1400px]  rounded-3xl m-auto p-8 bg-white bordered">
        <h1 className='text-lg lg:text-4xl text-center'>  {translate?.contact_access} </h1>
        <div className="grid grid-cols-2 lg:grid-cols-4  gap-8 w-full mt-2 ">
          <div className="flex col-span-2 2 flex-col items-center justify-center ">
            <div className="w-5/6 h-5/6 bg-gray-100" >
              <iframe className="w-full h-full" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d484.37077112246953!2d100.545463!3d13.780913!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb1b7e1d880341434!2sEquitable%20Education%20Fund!5e0!3m2!1sen!2sth!4v1663312108615!5m2!1sen!2sth"></iframe>
            </div>
          </div>
          <div className="flex col-span-2 2 flex-col items-center justify-center ">
            <div className="text-base lg:text-2xl mt-2"> {translate?.EEF_FULL} </div>
            <div className="text-sm lg:text-xl">  {translate?.adddress_1} </div>
            <div className="text-sm lg:text-xl">  {translate?.adddress_2} </div>
            <div class="grid grid-cols-3 divide-x mt-3">
              <div className='text-center '>
                <div>{translate?.EMAIL}</div>
                <div>isee@eef.or.th</div>
              </div>
              <div className='text-center'>
                <div>{translate?.TEL}</div>
                <div> 02-079-5475</div>
              </div>
              <div className='text-center'>
                <div>{translate?.FEX}</div>
                <div> 02-619-1810, 02-619-1812</div>
              </div>
            </div>
            <div className='w-full lg:w-[500px] mt-2'>
              <div className='my-2'>
                <Input rows={5} placeholder="ระบุ Email ผู้ส่ง" onChange={onChangeEmail} value={email} />
              </div>
              <TextArea
                showCount
                rows={5}
                placeholder="โปรดระบุข้อความ"
                maxLength={2000}
                onChange={onChangeMessage}
                value={message}
              />
                <TurnStile token={token} setToken={setToken} />
              <div className='flex w-full py-2 justify-center'>
                <button class="inline-flex w-full text-center text-white bg-green-500 hover:bg-green-800 border-0 py-2 px-6 focus:outline-nonerounded text-md" onClick={() => onSend()}><span className='m-auto text-xl'>{translate?.SEND_EMAIL}</span></button>
              </div>
            </div>
          </div>
        </div>
        <div className='mb-24 lg:mb-0'></div>
      </div>
    </Layout >
  );
}
